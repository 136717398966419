import React, { useEffect, useContext, useState } from 'react';
import { Button, Container, Dropdown, Grid, Header, Icon, Image, Input, Message, Modal, Segment, Select, Table } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getlinkplayerprofile, getZoomT, noSubIsActive } from '../myfunctions';
import { ConnectWS } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';

import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import ShowImgAvatar from './ShowImgAvatar';
import useCheckUserIsDemo from '../hooks/useCheckUserIsDemo';
import { useTranslation } from 'react-i18next';
import EditLeagueMatchDetailsExMatcheTs from './EditLeagueMatchDetailsExType/EditLeagueMatchDetailsExMatcheTs';
import { confirmAlert } from 'react-confirm-alert';

function EditLeagueMatchDetailsEx(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)
  const [match_details_ex, setmatch_details_ex] = useState(null)
  const [match_details_ex_type, setmatch_details_ex_type] = useState(0)
  const [loading, setloading] = useState(false)
  const isDemo = useCheckUserIsDemo()
  const [isAS, setisAS] = useState(false)
  const { t } = useTranslation();
  function checkSubIsActiv() {
    try {

      if (isDemo) {
        setisAS(true)
      } else if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
        setisAS(true)
      } else {
        setisAS(false)
      }
    } catch (e) {
      setisAS(false)
    }
  }

  useEffect(() => {
    if (props.openModalmatch_details_ex.open) {
    
      setmatch_details_ex(null)
      setmatch_details_ex_type(0)

      checkSubIsActiv()
      setOpen(props.openModalmatch_details_ex.open)

      if (props.openModalmatch_details_ex.row.match_details_ex) {
        console.log(props.openModalmatch_details_ex.row.match_details_ex)
        
        try {
          prestart(JSON.parse(JSON.stringify(props.openModalmatch_details_ex.row.match_details_ex)))
        } catch (e) {

        }

      }

    } else {
      setOpen(props.openModalmatch_details_ex.open)

    }
    return () => {
     
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.openModalmatch_details_ex, authContext.refreshView])// eslint-disable-line react-hooks/exhaustive-deps


  function getMatchDetails(match_member1_id, match_member2_id) {
    try {
      if (props.openModalmatch_details_ex && props.openModalmatch_details_ex.open && props.openModalmatch_details_ex.row.match_details) {
        let tmparr = JSON.parse(props.openModalmatch_details_ex.row.match_details).score
        let tmparr2 = []

        tmparr.forEach((row1) =>
          tmparr2.push(" " + row1[match_member1_id] + ":" + row1[match_member2_id])
        )
        return <span style={{ fontSize: 18 }}>{tmparr2.toString()}</span>
      } else {
        return ""
      }


    } catch (e) {
      console.log(e)
      return ""
    }

  }

  function prestart(match_details_ex) {

    if (match_details_ex) {
     
      let match_details_extmp = JSON.parse(JSON.stringify(match_details_ex))

      // definiowane inne statystyki meczowe
      if (match_details_extmp.matche_ts) {

        setmatch_details_ex(match_details_extmp)
        setmatch_details_ex_type(1)
        return
      }



      for (const [key] of Object.entries(match_details_extmp.members).sort((a, b) => a[1].sort - b[1].sort)) {

        if (match_details_extmp.members[key].team1_id === null) {
          match_details_extmp.members[key].team1_id = props.openModalmatch_details_ex.row.member1_id
        }
        match_details_extmp.members[key].team1_members = startCreateJsonTeam(props.openModalmatch_details_ex.row.member1_id, match_details_extmp.members[key].team1_members)

        if (match_details_extmp.members[key].team2_id === null) {
          match_details_extmp.members[key].team2_id = props.openModalmatch_details_ex.row.member2_id
        }
        match_details_extmp.members[key].team2_members = startCreateJsonTeam(props.openModalmatch_details_ex.row.member2_id, match_details_extmp.members[key].team2_members)
      }


      setmatch_details_ex(match_details_extmp)
      setmatch_details_ex_type(0)


    }
  }

  function startCreateJsonTeam(team_id, team2_members) {
    try {

      // szukanie druzyny
      let team = []
      if (props.openModalmatch_details_ex.st.members && props.openModalmatch_details_ex.st.members.length > 0) {

        props.openModalmatch_details_ex.st.members.forEach((row) => {
          if (row.member_id === team_id) {
            team.push(...row.members_list)
          }
        })
      }

      if (team.length > 0) {

        var obj = {};
        team.forEach((row) => {

          obj[row.member_team_id] = { value: team2_members && team2_members[row.member_team_id] && team2_members[row.member_team_id].value ? team2_members[row.member_team_id].value : null }
        })

        return { ...obj }
      } else {
        return null
      }

    } catch (e) {
      console.log(e)
      addToast("Wystąpił błąd!", { appearance: 'error' });
      return null
    }

  }

  function getValueOther(team_id, json, team_nr) {
    if (json.team1_id === null && team_nr === 1) {
      return null
    } else if (json.team2_id === null && team_nr === 2) {
      return null
    } else if (json.team1_id !== null && team_nr === 1) {
      return json.team1_member
    } else if (json.team2_id !== null && team_nr === 2) {
      return json.team2_member
    }
  }

  function updateJsonOrder(value, key, team_nr, team_id) {

    let jsonTmp = { ...match_details_ex }

    if (team_nr === 1) {
      jsonTmp.others[key].team1_id = team_id
      jsonTmp.others[key].team1_member = value
    } else if (team_nr === 2) {
      jsonTmp.others[key].team2_id = team_id
      jsonTmp.others[key].team2_member = value
    } else {

    }
    setmatch_details_ex({ ...jsonTmp })

  }

  function getOthersInfo(team_id, team_nr) {
    try {
      const rows = [];

      if (match_details_ex && match_details_ex.others) {
        for (const [key, value] of Object.entries(match_details_ex.others).sort((a, b) => a[1].sort - b[1].sort)) {

          if (props.openModalmatch_details_ex.onlyView) {
            rows.push(
              <div><Icon color='grey' name={value.icon} />{t("stats." + value.name)}: {getValueOther(team_id, value, team_nr) ? getValueOther(team_id, value, team_nr) : <i>{t("BrakDanych")}</i>}</div>
            )
          } else {
            if (value.type === 'inputnumber') {
              rows.push(
                <div><Icon color='grey' name={value.icon} />{t("stats." + value.name)}: <Input type="number" value={getValueOther(team_id, value, team_nr)} onChange={(e, d) => updateJsonOrder(d.value, key, team_nr, team_id)} /></div>
              )
            } else {
              rows.push(
                <div><Icon color='grey' name={value.icon} />{t("stats." + value.name)}: <Input value={getValueOther(team_id, value, team_nr)} onChange={(e, d) => updateJsonOrder(d.value, key, team_nr, team_id)} /></div>
              )
            }
          }

        }
        return <Segment textAlign='left'>{rows}</Segment>
      }
    } catch (e) {
      console.log(e)
    }

  }


  function getAllMembers(team_id, member_img_file, team_nr) {
    try {

      let team = []
      if (props.openModalmatch_details_ex.st.members && props.openModalmatch_details_ex.st.members.length > 0) {

        props.openModalmatch_details_ex.st.members.forEach((row) => {
          if (row.member_id === team_id) {
            team.push(...row.members_list)
          }
        })
      }
      const rows = [];
      const cells = [];
      for (const [key, value] of Object.entries(match_details_ex.members).sort((a, b) => a[1].sort - b[1].sort)) {
        rows.push(
          <Table.HeaderCell textAlign='center' width={1} style={{ lineHeight: "12px", textTransform: "uppercase" }}>{t("stats." + value.name)}</Table.HeaderCell>
        )
        cells.push(
          { ...value, id: key }
        )

      }
      if (team.length > 0) {
        return <><div style={{ overflowX: "auto", width: "100%" }} >
          <Table size='small' celled striped compact unstackable >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign='center'>LP</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' style={{ lineHeight: "12px" }}><figure className='imgPlayer'><ShowImgAvatar urlpic={member_img_file} mtype={"USER"} /><figcaption> {t("WidokTurnieju.zawodnik")}</figcaption></figure></Table.HeaderCell>
                {rows}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {team.map((row, i) =>
                <Table.Row key={i}   >
                  <Table.Cell textAlign='center'>{i + 1}</Table.Cell>

                  <Table.Cell><figure className='imgPlayer'><ShowImgAvatar urlpic={row.member_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, row.member_name, 0, 14)}</figcaption></figure></Table.Cell>
                  {cells.map((row1, j) =>
                    <Table.Cell width={1} textAlign='center'>{getValuRow(row.member_team_id, row1, team_id)}</Table.Cell>
                  )}
                </Table.Row>
              )}

            </Table.Body>
          </Table>
        </div>

          {getOthersInfo(team_id, team_nr)}
        </>
      } else {
        return <Message>{t("WidokTurnieju.brak_przypisanych_zawodnikow")}</Message>
      }

    } catch (e) {
      return ""
    }
  }

  const optionsBool = [
    { key: 'TAK', text: 'TAK', value: true },
    { key: 'NIE', text: 'NIE', value: false },
  ]

  const optionsBoolCards = [
    { key: 'yellow', text: '', value: 'yellow', image: { avatar: true, src: process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png" } },
    { key: 'red', text: '', value: 'red', image: { avatar: true, src: process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png" }, },
    { key: 'yellow-red', text: '', value: 'yellow-red', image: { avatar: true, src: process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png" }, },
    { key: 'brak', text: 'Brak', value: null }
  ]

  function getCardsImg(value) {
    if (value === "yellow") {
      return <Image style={{ height: 20, margin: "0 auto" }} src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png"} />
    } else if (value === "red") {
      return <Image style={{ height: 20, margin: "0 auto" }} src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png"} />
    } else if (value === "yellow-red") {
      return <Image style={{ height: 20, margin: "0 auto" }} src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png"} />
    } else {
      return ""
    }

  }

  function getValuRow(member_team_id, row1, team_id) {
    try {
      if (row1.team1_id === team_id) {
        if (props.openModalmatch_details_ex.onlyView) {
          return row1.type === "boolimagecard" ? getCardsImg(row1.team1_members[member_team_id].value) : row1.type === "inputboolicon" ? row1.team1_members[member_team_id].value === true ? <Icon color='yellow' name={row1.icon} /> : "" : row1.type === "inputbool" ? row1.team1_members[member_team_id].value === null ? "NIE" : row1.team1_members[member_team_id].value === true ? "TAK" : "NIE" : row1.team1_members[member_team_id].value
        } else if (row1.type === "inputbool" || row1.type === "inputboolicon") {
          return <Select compact options={optionsBool} value={row1.team1_members[member_team_id].value === null ? false : row1.team1_members[member_team_id].value} onChange={(e, d) => updateJson(member_team_id, row1, team_id, d.value)} />
        } else if (row1.type === "boolimagecard") {
          return <Dropdown inline options={optionsBoolCards} value={row1.team1_members[member_team_id].value} onChange={(e, d) => updateJson(member_team_id, row1, team_id, d.value)} />
        } else {
          return <Input style={{ width: row1.type === "inputnumber" ? 80 : 120 }} type={row1.type === "inputnumber" ? "number" : row1.type.type === "inputurl" ? "url" : "text"} value={row1.team1_members[member_team_id].value} onChange={(e, d) => updateJson(member_team_id, row1, team_id, d.value)} />
        }

      } else if (row1.team2_id === team_id) {
        if (props.openModalmatch_details_ex.onlyView) {
          return row1.type === "boolimagecard" ? getCardsImg(row1.team2_members[member_team_id].value) : row1.type === "inputboolicon" ? row1.team2_members[member_team_id].value === true ? <Icon color='yellow' name={row1.icon} /> : "" : row1.type === "inputbool" ? row1.team2_members[member_team_id].value === null ? "NIE" : row1.team2_members[member_team_id].value === true ? "TAK" : "NIE" : row1.team2_members[member_team_id].value
        } else if (row1.type === "inputbool" || row1.type === "inputboolicon") {
          return <Select compact options={optionsBool} value={row1.team2_members[member_team_id].value === null ? false : row1.team2_members[member_team_id].value} onChange={(e, d) => updateJson(member_team_id, row1, team_id, d.value)} />
        } else if (row1.type === "boolimagecard") {
          return <Dropdown inline options={optionsBoolCards} value={row1.team2_members[member_team_id].value} onChange={(e, d) => updateJson(member_team_id, row1, team_id, d.value)} />
        } else {
          return <Input style={{ width: row1.type === "inputnumber" ? 80 : 120 }} type={row1.type === "inputnumber" ? "number" : row1.type.type === "inputurl" ? "url" : "text"} value={row1.team2_members[member_team_id].value} onChange={(e, d) => updateJson(member_team_id, row1, team_id, d.value)} />
        }

      } else {
        return "?"
      }

    } catch (e) {
      return "?"
    }
  }

  function updateJson(member_team_id, row1, team_id, value) {

    let jsonTmp = { ...match_details_ex }

    if (row1.team1_id === team_id) {
      jsonTmp.members[row1.id].team1_members[member_team_id].value = value
    } else if (row1.team2_id === team_id) {
      jsonTmp.members[row1.id].team2_members[member_team_id].value = value
    } else {

    }

    setmatch_details_ex({ ...jsonTmp })

  }

  function exitModal() {

    props.setopenModalmatch_details_ex({ open: false, row: null, onlyView: 1, st: null })
    setOpen(false)
  }


  function saveDBData() {
    let match_id = props.openModalmatch_details_ex.row.subtournament_league_match_id
    setloading(true)
    ConnectWS("/generator/setMatchDetailsEx", authContext.token, { match_id: match_id, match_details_ex: { ...match_details_ex } }, function (response, logout) {
      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        exitModal()
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloading(false)

    });
  }


  function restoreStats() {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz zresetować statystyki dla tego pojedynku?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => restoreStatsacc()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function restoreStatsacc() {

      let match_id = props.openModalmatch_details_ex.row.subtournament_league_match_id
      setloading(true)
      ConnectWS("/generator/setMatchDetailsEx", authContext.token, { match_id: match_id, match_details_ex: null }, function (response, logout) {
        if (logout === 1) {


        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          exitModal()
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }
        setloading(false)

      });
    }
  }


    return (


      <Modal

        open={open}
        style={{ paddingBottom: 1, zoom: getZoomT(width) }}

        size='fullscreen'


      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='left'>
                  <Icon name='balance scale' color="red" />

                  <Header.Content>{t("WidokTurnieju.statystyki_meczow")}
                    <Header.Subheader>
                      {t("WidokTurnieju.statystyki_meczow1")}
                    </Header.Subheader>
                  </Header.Content>


                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='medium' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#e0e1e2" }} >
          <Modal.Description >
            {open && props.openModalmatch_details_ex && props.openModalmatch_details_ex.row ?

              <>


                <Segment>
                  <Grid fluid verticalAlign='middle' >
                    <Grid.Row>
                      <Grid.Column width={6} textAlign='center'>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <figure className='imgPlayer'><span style={{ marginRight: 5 }}>{getlinkplayerprofile(null, props.openModalmatch_details_ex.row.member1_name, 0, 20)}</span><ShowImgAvatar urlpic={props.openModalmatch_details_ex.row.member1_img_file} mtype={"0"} msize={width > 600 ? "large" : "massive"} /><figcaption></figcaption></figure>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={4} textAlign='center' style={{ fontSize: 30 }}>
                        {props.openModalmatch_details_ex.row.member1_score !== null && props.openModalmatch_details_ex.row.member2_score !== null ? <>
                          {props.openModalmatch_details_ex.row.member1_score}:{props.openModalmatch_details_ex.row.member2_score}
                        </> : <>-:-</>}


                      </Grid.Column>
                      <Grid.Column width={6} textAlign='center'>
                        <figure style={{ float: "left" }} className='imgPlayer'><ShowImgAvatar urlpic={props.openModalmatch_details_ex.row.member2_img_file} mtype={"0"} msize={width > 600 ? "large" : "massive"} /><figcaption>{getlinkplayerprofile(null, props.openModalmatch_details_ex.row.member2_name, 0, 20)}</figcaption></figure>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Container textAlign='center'>{getMatchDetails(props.openModalmatch_details_ex.row.match_member1_id, props.openModalmatch_details_ex.row.match_member2_id)}</Container>
                </Segment>

                {match_details_ex_type === 1 ? <>
                  <EditLeagueMatchDetailsExMatcheTs onlyView={props.openModalmatch_details_ex.onlyView} row={props.openModalmatch_details_ex.row} members={props.openModalmatch_details_ex.st.members} match_details_ex={match_details_ex} setmatch_details_ex={setmatch_details_ex} />
                </> :
                  <Grid stackable style={{ marginBottom: 10 }}>
                    <Grid.Row>
                      <Grid.Column width={8} textAlign='center'>

                        {getAllMembers(props.openModalmatch_details_ex.row.member1_id, props.openModalmatch_details_ex.row.member1_img_file, 1)}
                      </Grid.Column>

                      <Grid.Column width={8} textAlign='center'>

                        {getAllMembers(props.openModalmatch_details_ex.row.member2_id, props.openModalmatch_details_ex.row.member2_img_file, 2)}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                }

              </>
              : ""}

            {props.openModalmatch_details_ex.onlyView === 1 || !match_details_ex ? "" : isAS ?
              <Button size='mini' fluid color='blue' loading={loading} disabled={loading} onClick={() => saveDBData()}>ZAPISZ</Button>
              : <>
                <Button size='mini' fluid color='blue' disabled={true} >ZAPISZ</Button>
              </>}


            {isAS || props.openModalmatch_details_ex.onlyView === 1 ? "" : noSubIsActive()}
            {props.openModalmatch_details_ex.onlyView === 1 ? "" : match_details_ex ?


              <Message size='mini' info>
                Potrzebujesz dodać inne statystyki dla zawodników? Nie ma problemu! Napisz do Nas <a href={"mailto:biuro@turniej-generator.pl"} className="linktl" target="_blank" rel="noopener noreferrer">biuro@turniej-generator.pl</a>
              </Message>


              :
              <Message size='mini' info>
                Brak włączonych statystyk dla tej ligi! Napisz do Nas <a href={"mailto:biuro@turniej-generator.pl"} className="linktl" target="_blank" rel="noopener noreferrer">biuro@turniej-generator.pl</a>
              </Message>
            }
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {props.openModalmatch_details_ex.onlyView === 1 ? "" : match_details_ex ? <Button size="tiny" floated='left' onClick={() => restoreStats()} content={t("ResetStats")} /> : ""}
          <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content={t("Wyjdz")} />
        </Modal.Actions>
      </Modal>



    )
  }

  export default EditLeagueMatchDetailsEx;