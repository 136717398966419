import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Input, Message, Progress } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import useAuth from '../../../../hooks/useAuth';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useToasts } from 'react-toast-notifications';
import { removeEmojis, removeSpecialChars, txtCase } from '../../../../myfunctions';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import validator from 'validator'
import { useTranslation } from 'react-i18next'

function MyTournamentAddUsersActivity(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const isDemo = useCheckUserIsDemo()

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyTournamentAddUsersActivity")
    }


    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.members_limit, props.count_member])// eslint-disable-line react-hooks/exhaustive-deps


  function showIndoAddUsersS(nazwa, message, error) {
    authContext.changeModalInfo({ viewClickOff: true, nameModal: "ADDTS", name: nazwa, value: message, error: error })
  }

  const [formAddUsers, setFormAddUsers] = useState({
    name: "",
    surname: "",
    email: "",
    validEmail: true,
  });

 


  const addDBUsersToTournament = e => {
    e.preventDefault();

    if (validator.isEmail(formAddUsers.email.trim()) || props.registrationTournament===false) {
    } else {
      setFormAddUsers({ ...formAddUsers, validEmail: false })   
      return
    }

    let nametmp
    if (props.subTurnamentType === "USER") {
      nametmp = txtCase(formAddUsers.name.trim()) + " " + txtCase(formAddUsers.surname.trim())
    } else {
      nametmp = txtCase(formAddUsers.name.trim())
    }

    setloading(true)

    ConnectWS("/activity/addMemberToActivity", authContext.token, { activity_id: props.activity_id, member_name: nametmp, email: formAddUsers.email.trim(), source_guest: props.registrationTournament, limit_users: props.members_limit }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        setFormAddUsers({
          name: "",
          surname: "",
          email: "",
          validEmail: true,
        })
        if (props.registrationTournament) {
          showIndoAddUsersS("Potwierdź zapisy", <> Aby zakończyć proces zapisu, prosimy o potwierdzenie swojego adresu e-mail.<br />
            Właśnie wysłaliśmy do Ciebie wiadomość z linkiem weryfikacyjnym. Kliknij na ten link, aby potwierdzić swoje zapisy.
          </>, false)
        } else {
          addToast(response.data.message, { appearance: 'success' });
        }
        authContext.changeRefreshView(authContext.refreshView + 1)

      } else {

        if (response.includes("nazwa jest niezgodna")) {
          showIndoAddUsersS("Informacja", response, true)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }
        //console.log(response);
      }
      setloading(false)


    });

  }



  return (
    <>
      {props.count_member >= props.members_limit ? <Message warning size='mini'>
        <Message.Header>Brak możliwości zapisu uczestnika</Message.Header>
        <p>Limit <b>{props.members_limit}</b> uczestników został osiągnięty.</p>
      </Message> : <>
        <Progress className={Number(props.count_member) > 2 ? 'tgprogress' : 'tgprogressp'} value={props.count_member} total={props.members_limit} progress='ratio' error>
          <span style={{ color: props.isDark ? "#e07e7e" : '#d93f3f' }}> Limit {props.members_limit} uczestników, zapisanych {props.count_member}</span>
        </Progress>
        <Form size='mini' onSubmit={addDBUsersToTournament} style={{ textAlign: "left" }}>
          {props.subTurnamentType === "USER" ?
            <>
              <Form.Field
                control={Input}
                value={formAddUsers.name}
                onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
                label={t("WidokTurnieju.zapisy_imie")}
                required={props.subTurnamentType === "USER" ? true : false}
                placeholder={t("WidokTurnieju.zapisy_imie")}

              />
              <Form.Field
                control={Input}
                value={formAddUsers.surname}
                onChange={(event, data) => setFormAddUsers({ ...formAddUsers, surname: removeSpecialChars(removeEmojis(data.value)) })}
                required={props.subTurnamentType === "USER" ? true : false}
                label={t("WidokTurnieju.zapisy_nazwisko")}
                placeholder={t("WidokTurnieju.zapisy_nazwisko")}

              />
            </>
            : <Form.Field
              control={Input}
              value={formAddUsers.name}
              required={props.subTurnamentType !== "USER" ? true : false}
              onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
              label={t("WidokTurnieju.zapisy_druzyna")}
              placeholder={t("WidokTurnieju.zapisy_druzyna")}

            />}
          <Form.Field
            control={Input}
            value={formAddUsers.email}
            onChange={(event, data) => setFormAddUsers({ ...formAddUsers, email: data.value })}
            label={t("WidokTurnieju.zapisy_email")}
            required={props.registrationTournament === true ? true : false}
            placeholder={t("WidokTurnieju.zapisy_email")}
            type='email'
            error={formAddUsers.validEmail ? null : "Niepoprawny formatu adresu email"}
            disabled={props.tournamentFree === 0 ? true : false || isDemo ? true : false}
          />

          <Button size='mini' color={props.registrationTournament ? "blue" : "grey"} loading={loading} disabled={loading} fluid type='submit'>{props.registrationTournament ? t("WidokTurnieju.zapisy_btn") : "DODAJ"}</Button>
        </Form>
      </>}
    </>
  )
}

export default MyTournamentAddUsersActivity;