import React, { useEffect, useContext, useState } from 'react';
import { Tab, Segment, Menu, Icon } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { changePageName, checkiscompanypay, checkIsREFTOKENOn } from '../../../../myfunctions';
import ChangePassword from './ChangePassword';
import ChangeMyData from './ChangeMyData';
import PackagesGTL from './PackagesGTL';
import ViewRoot from './ViewRoot';
import MyReferrals from './MyReferrals';
import ShowFirstLoginPage from '../../../../tools/ShowFirstLoginPage';
import MyPayments from './MyPayments';
import WebsiteConfigurator from './WebsiteConfigurator';
//import Voucher from './Voucher';

function MyAccount(props) {
  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [activeIndex, setactiveIndex] = useState(0);
  const [panes, setpanes] = useState([]);
  const handleTabChange = (e, { activeIndex }) => setactiveIndex(activeIndex)



  useEffect(() => {
    changePageName()
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyAccount")
    }

    let panestmp = [
      { menuItem: { key: 'wk', icon: 'users', content: 'Widok konta' }, render: () => <Tab.Pane><ViewRoot setactiveIndex={setactiveIndex} /></Tab.Pane> },
      { menuItem: { key: 'ab', icon: 'certificate', content: 'Pakiety - Cennik' }, render: () => <Tab.Pane><PackagesGTL /></Tab.Pane> },
      //{ menuItem: { key: 'voucher', icon: 'credit card', content: 'Voucher' }, render: () => <Tab.Pane><Voucher /></Tab.Pane> },
  
      { menuItem: { key: 'ed', icon: 'edit', content: 'Moje dane' }, render: () => <Tab.Pane><ChangeMyData /></Tab.Pane> },
      { menuItem: { key: 'pass', icon: 'unlock', content: 'Zmień hasło' }, render: () => <Tab.Pane><ChangePassword /></Tab.Pane> },
      checkiscompanypay() ? { menuItem: { key: 'payment', icon: 'payment', content: 'Moje płatności' }, render: () => <Tab.Pane><MyPayments/></Tab.Pane> } :"",
      { menuItem: { key: 'ks', icon: 'globe', content: 'Konfigurator strony' }, render: () => <Tab.Pane><WebsiteConfigurator  /></Tab.Pane> },
    ]
  
    if(checkIsREFTOKENOn()){
      panestmp.push({ menuItem:   (<Menu.Item key='messages' color='red'>
      <span style={{color:"orange"}}><Icon name="handshake"/>Poleć innym</span>
    </Menu.Item>), render: () => <Tab.Pane><MyReferrals /></Tab.Pane> })
    }

    setpanes(panestmp)
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
       <ShowFirstLoginPage />
      <Segment>
     
        {width < 1200 ?
          <Tab activeIndex={activeIndex} onTabChange={handleTabChange} menu={{ className: "wrapped", color: "red" }} panes={panes} />
          :
          <Tab activeIndex={activeIndex} onTabChange={handleTabChange} menu={{ fluid: true, secondary: true, pointing: true, className: "wrapped", color: "red" }} panes={panes} />
        }
        
      </Segment>
    </>
  )
}

export default MyAccount;